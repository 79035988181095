import { useEffect, useState } from "react";

export function LoadingDots() {
  const [dots, setDots] = useState("...");
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots === "..." ? "." : dots + "."));
    }, 500);
    return () => clearInterval(interval);
  });
  return <>{dots}</>;
}
