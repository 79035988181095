import React from "react";
import { useLocalStorage } from "usehooks-ts";
import { authStateAtom, authenticate } from "./api";
import { Header, Section, Input, Page } from "./components";
import { useMutation } from "react-query";
import penguin from "./penguin_rocket.gif";
import { useAtomValue } from "jotai";
import { navigate } from ".";

export function Auth() {
  const [username, setUsername] = useLocalStorage("username", "");
  const [password, setPassword] = useLocalStorage("password", "");
  const authMutation = useMutation({
    mutationFn: () => {
      return authenticate("/");
    },
  });
  const authState = useAtomValue(authStateAtom);
  React.useEffect(() => {
    if (authState !== null) {
      navigate("/");
    }
  }, [authState]);

  return (
    <Page>
      <Header />
      <Section>
        <div className="mb-4 w-full">
          <Input
            placeholder="username"
            value={username}
            setValue={setUsername}
          />
        </div>
        <div className="mb-4 w-full">
          <Input
            placeholder="password"
            value={password}
            setValue={setPassword}
            type="password"
          />
        </div>
        {authMutation.isLoading ? (
          <img
            className="w-20 h-20 opacity-80 rounded absolute -bottom-2"
            src={penguin}
            alt="penguin rocket"
          />
        ) : (
          <button
            onClick={() => authMutation.mutate()}
            className="text-violet-500 text-lg underline"
          >
            login
          </button>
        )}
      </Section>
    </Page>
  );
}
