import { useState } from "react";
import { Meeting, PaymentMethods, useBookMeetingMutation } from "./api";
import _ from "lodash";
import { dateToShortStr } from "./lib";
import penguin from "./penguin_money.gif";
import { atom, useAtom, useSetAtom } from "jotai";
import { scheduleExpandedDateAtom } from "./Schedule";

export function Checkbox(props: { checked: boolean; onChange: () => void }) {
  return (
    <div>
      <div
        className="h-7 w-7 bg-blue-200 rounded-md flex flex-row justify-center items-center"
        onClick={() => props.onChange()}
      >
        {props.checked && (
          <div className="h-5 w-5 bg-purple-300 rounded-md"></div>
        )}
      </div>
    </div>
  );
}

type BookingModalInfo = {
  session: Meeting & { date: Date };
  // Currently, paymentMethods is passed in as using maximum credits if possible.
  paymentMethods: PaymentMethods;
  timeString: string;
};
// Controls whether to show the booking modal or not. If non-null, the modal
// uses the atom state as the initial data for rendering.
export const bookingModalInfoAtom = atom<BookingModalInfo | null>(null);

/**
 * Right now this modal only launches when user is about to book with credits.
 * Then it shows a booking modal to confirm the user wants to use credits.
 */
export function BookingModal() {
  const [bookingModalInfo, setBookingModalInfo] = useAtom(bookingModalInfoAtom);
  const setScheduleExpandedDate = useSetAtom(scheduleExpandedDateAtom);

  const [useCredits, setUseCredits] = useState(true);
  const bookMutation = useBookMeetingMutation();

  if (!bookingModalInfo) {
    return null;
  }
  const { session, paymentMethods, timeString } = bookingModalInfo;
  const cost = session.cost;
  const creditsUsing = useCredits
    ? _.sumBy(
        paymentMethods.filter((pm) => pm.type !== "creditCard"),
        "amount"
      )
    : 0;
  const ccPayment = cost - creditsUsing;
  const headerStr = `booking ${dateToShortStr(session.date)}`;

  return (
    <>
      {/* background */}
      <div className="z-10 fixed h-screen w-full bg-slate-700 opacity-50"></div>
      <div
        className="z-20 fixed w-full h-full flex flex-col justify-center items-center"
        onClick={() => setBookingModalInfo(null)}
      >
        {/* modal card */}
        <div
          className="rounded-xl w-4/5 bg-purple-100 flex flex-col p-8"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="text-xl mb-12">{headerStr}</div>
          <div
            className="w-full text-2xl mb-12"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr 2fr",
              gridTemplateRows: "5fr 5fr 1fr 5fr",
              rowGap: "2px",
              columnGap: "12px",
            }}
          >
            <div className="contents text-right">
              <span className="col-start-2 row-start-1">cost</span>
              <span
                className={`col-start-2 row-start-2 text-green-600 ${
                  !useCredits && "opacity-50"
                }`}
                onClick={() => setUseCredits(!useCredits)}
              >
                use credits
              </span>
              <span className="col-start-2 row-start-4">payment</span>
            </div>
            <div className="contents text-right">
              <span className="col-start-3 row-start-1">${session.cost}</span>
              <span
                className={`col-start-3 row-start-2 text-green-600 ${
                  !useCredits && "opacity-50"
                }`}
                onClick={() => setUseCredits(!useCredits)}
              >
                {useCredits ? `-$${creditsUsing}` : "$0"}
              </span>
              <span className="col-start-3 row-start-4">${ccPayment}</span>
            </div>
            <span className="col-start-1 row-start-2">
              <Checkbox
                checked={useCredits}
                onChange={() => setUseCredits(!useCredits)}
              />
            </span>
            <span className="flex items-center col-span-3 row-start-3 w-full">
              <span className="flex-grow h-0 border-b border-gray-400" />
            </span>
          </div>
          {bookMutation.isLoading ? (
            <img
              className="relative self-center -top-7 w-20 h-20 opacity-80 rounded"
              src={penguin}
              alt="penguin money"
            />
          ) : bookMutation.isSuccess ? (
            <div className="flex flex-col items-center">
              <div>successfully booked! 🎉</div>
            </div>
          ) : (
            <button
              className="self-center text-xl w-40 h-12 p-4 bg-violet-300 rounded border-2 border-blue-200 flex items-center justify-center font-light"
              onClick={async () => {
                await bookMutation.mutateAsync({
                  session,
                  timeString,
                  paymentMethods: useCredits
                    ? paymentMethods
                    : [{ type: "creditCard", amount: cost }],
                });
                // Close schedule single-date view
                setScheduleExpandedDate(null);
                setTimeout(() => {
                  setBookingModalInfo(null);
                }, 5000);
              }}
            >
              confirm
            </button>
          )}
        </div>
      </div>
    </>
  );
}
