import React, { HTMLInputTypeAttribute } from "react";
import { Toaster } from "react-hot-toast";
import { AccountMemberInfo } from "./AccountMemberInfo";

export function Header() {
  return (
    <div className="flex flex-row py-8 w-full px-2 sm:px-0 items-end justify-between text-slate-600">
      <h1 className="text-3xl">skeetbowl!</h1>
      <AccountMemberInfo />
    </div>
  );
}

export function Input({
  value,
  placeholder,
  setValue,
  type,
}: {
  value: string;
  placeholder: string;
  setValue: (value: string) => void;
  type?: HTMLInputTypeAttribute;
}) {
  return (
    <input
      className="h-10 p-3 w-full text-2xl rounded border-4 border-blue-200 font-light bg-inherit"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    ></input>
  );
}

export function Section({ children }: { children: React.ReactNode }) {
  return (
    <div className="relative w-full p-4 rounded border-1 bg-cyan-50 flex-col items-center inline-flex">
      {children}
    </div>
  );
}

export function Page({ children }: { children: React.ReactNode }) {
  return (
    <div
      id="page"
      className="flex flex-col items-center font-light text-slate-700"
    >
      <div className="w-full max-w-lg">{children}</div>
      <Toaster />
    </div>
  );
}
