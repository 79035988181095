import { useAuthenticate } from "./api";
import { QuickBook } from "./QuickBook";
import { Header, Page } from "./components";
import { Schedule } from "./Schedule";
import { Maintenance } from "./Maintenance";
import { createPortal } from "react-dom";
import { BookingModal, bookingModalInfoAtom } from "./BookingModal";
import { useAtomValue } from "jotai";

// const now = new Date().toISOString();
const inMaintenance = false;
function App() {
  useAuthenticate();
  const bookingModalInfo = useAtomValue(bookingModalInfoAtom);
  if (inMaintenance) {
    return (
      <Page>
        <Maintenance />
      </Page>
    );
  }

  return (
    <Page>
      <Header />
      <QuickBook />
      <div className="mb-4" />
      <Schedule />
      {bookingModalInfo &&
        createPortal(<BookingModal />, document.getElementById("page")!)}
    </Page>
  );
}

export default App;
