export async function get(
  url: string,
  query: { [key: string]: string },
  bearerToken?: string
) {
  const response = await fetch(url + "?" + new URLSearchParams(query), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  const json = await response.json();
  if (response.status >= 400 && response.status < 500) {
    throw new Error("Auth error");
  }
  return json;
}

export async function post(
  url: string,
  query: { [key: string]: string },
  body: Object,
  bearerToken?: string
) {
  const response = await fetch(url + "?" + new URLSearchParams(query), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify(body),
  });
  const json = await response.json();
  if (response.status >= 400 && response.status < 500) {
    throw new Error("Auth error");
  }
  return json;
}
